import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconRebookSolid } from '@flixbus/honeycomb-icons-react';
import api from '../../../api/Client';
import { TranslateContext } from '../../System/Translations';

export default function Rebookr(props) {
    const { rideId, orders, selectedPax, rideUuid, isGhost } = props;
    const { config: CONFIG } = api;
    const translate = useContext(TranslateContext);
    const rebookRUrl = `${CONFIG.rebookr_host}${rideUuid}`;
    const ordersIdsSet = new Set();

    if (selectedPax.length) {
        orders.forEach((order) => {
            if (selectedPax.indexOf(order.id) !== -1) {
                ordersIdsSet.add(order.id);
            }
        });
    }

    const getAllOrdersIds = useCallback(
        function getAllOrdersIdsHandler() {
            const allOrdersIdSet = new Set();
            orders.forEach((order) => {
                allOrdersIdSet.add(order.id);
            });
            return Array.from(allOrdersIdSet);
        },
        [orders]
    );

    const getGhostMeta = useCallback(
        function getGhostMetaHandler() {
            const { id, passengers, route } = orders[0] || {};
            return {
                legacyId: rideId,
                orderId: id,
                orderItemId: passengers[0].order_item_id,
                startStationId: route?.from_uuid,
                destinationStationId: route?.to_uuid,
            };
        },
        [orders, rideId]
    );

    const messageHandler = useCallback(
        function messageHandlerCallback(e) {
            const { data, source } = e;
            if (data.channel === 'RebookR' && source !== null) {
                switch (data.action) {
                    case 'ready':
                        source.postMessage(
                            {
                                channel: 'Rideviewer',
                                payload: {
                                    rideUuid,
                                    ordersIds: isGhost
                                        ? getAllOrdersIds()
                                        : Array.from(ordersIdsSet),
                                    isGhost,
                                    ghostMeta: isGhost
                                        ? getGhostMeta()
                                        : undefined,
                                },
                            },
                            data.origin
                        );
                        break;
                    case 'complete':
                        window.removeEventListener('message', messageHandler);
                        break;
                    default:
                        return;
                }
            }
        },
        [ordersIdsSet, rideUuid, getAllOrdersIds, isGhost, getGhostMeta]
    );
    function communicateRebookr() {
        window.addEventListener('message', messageHandler);
    }

    const RebookRButton = () => (
        <Button
            Elem="a"
            rel="opener"
            href={rebookRUrl}
            target="_blank"
            link
            onClick={communicateRebookr}
        >
            <Icon appearance="primary" InlineIcon={IconRebookSolid} />
            &nbsp;
            {translate('reservations.rebook_btn')}
            {ordersIdsSet.size > 0 && `(${ordersIdsSet.size})`}
        </Button>
    );

    return (
        <React.Fragment>
            {isGhost && ordersIdsSet.size > 0 ? (
                <Tooltip
                    id="ghost-ride-warning"
                    openOnHover
                    position="bottom"
                    content="Status of this ride is a ghost. All orders will be pass to the RebookR ignoring selection"
                >
                    <span>
                        <RebookRButton />
                    </span>
                </Tooltip>
            ) : (
                <RebookRButton />
            )}
        </React.Fragment>
    );
}

Rebookr.propTypes = {
    orders: PropTypes.array,
    selectedPax: PropTypes.array,
    rideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    rideUuid: PropTypes.string.isRequired,
};
Rebookr.defaultProps = {
    orders: [],
    selectedPax: [],
};
