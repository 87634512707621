import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    Button,
    Spinner,
    Popup,
    PopupSection,
    Heading,
    Link,
} from '@flixbus/honeycomb-react';
import { Icon, IconSeatFree, IconNewTab } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../System/Translations';
import useSeatAvailable from './useSeatAvailable';
import useSeatReassignment from './useSeatReassignment';
import SeatAssignmentPaxList from './SeatAssignmentPaxList';

const SEAT_MAP_URL = 'https://anthill.ant.flix.tech/vehicle-layout/{rideId}';

export default function SeatAssignmentPopUp({
    rideUuid,
    orderId,
    passengers,
    onClose,
    isTr,
    ordersRefetch,
}) {
    const t = useContext(TranslateContext);
    const {
        coachesMap,
        reservations,
        segment,
        isLoading,
        isBus,
        error,
        getSeatAvailable,
    } = useSeatAvailable(rideUuid, orderId);
    const [seatSelection, setSeatSelection] = useState({});
    const { seatReassign, isLoading: isReassigning } = useSeatReassignment({
        rideUuid,
        orderId,
        isTr,
        ordersRefetch,
        isBus,
        close: onClose,
    });

    useEffect(() => {
        if (!isLoading && coachesMap === null) {
            getSeatAvailable();
        }
    }, [getSeatAvailable, isLoading, coachesMap]);

    function onSelect(options) {
        setSeatSelection(options);
    }
    const confirmSelection = useCallback(() => {
        seatReassign(seatSelection, reservations, segment);
    }, [seatSelection, seatReassign, reservations, segment]);

    return (
        <Popup active extraClasses="seats-popup">
            {isLoading ? (
                <PopupSection>
                    <Spinner />
                </PopupSection>
            ) : (
                <>
                    <PopupSection type="icon">
                        <Icon
                            InlineIcon={IconSeatFree}
                            size={12}
                            appearance="primary"
                            title="It's beer-o-clock"
                        />
                    </PopupSection>
                    <PopupSection type="title">
                        <Heading id="accessible-popup-title" size={3}>
                            {t('reservations.table.edit_seat_assignment')}
                        </Heading>
                        <Link
                            target="_blank"
                            extraClasses="seat-assignment-dialog__link"
                            href={SEAT_MAP_URL.replace('{rideId}', rideUuid)}
                        >
                            {t('reservations.table.seat_assignment_open_map')}{' '}
                            <Icon
                                InlineIcon={IconNewTab}
                                aria-label="Opens in a New Tab"
                                extraClasses="seat-assignment-dialog__link-icon"
                            />
                        </Link>
                    </PopupSection>
                    <PopupSection type="content">
                        <div
                            className={
                                isBus
                                    ? 'seat-assignment-dialog__seats seat-assignment-dialog__seats--not-train'
                                    : 'seat-assignment-dialog__seats'
                            }
                        >
                            {coachesMap?.size > 0 ? (
                                <SeatAssignmentPaxList
                                    passengers={passengers}
                                    isBus={isBus}
                                    coachesMap={coachesMap}
                                    onSelect={onSelect}
                                    rideUuid={rideUuid}
                                    orderId={orderId}
                                />
                            ) : null}
                            {error ? String(error) : null}
                        </div>
                    </PopupSection>
                    <PopupSection type="actions">
                        <Button onClick={onClose}>
                            {t('reservations.table.seat_assignment_close')}
                        </Button>
                        <Button
                            appearance="primary"
                            onClick={confirmSelection}
                            disabled={!Object.keys(seatSelection).length > 0}
                            loading={isReassigning}
                        >
                            {t('reservations.table.seat_assignment_confirm')}
                        </Button>
                    </PopupSection>
                </>
            )}
        </Popup>
    );
}
