import React, { useState } from 'react';
import { Icon, IconClose } from '@flixbus/honeycomb-icons-react';
import { Button, Link } from '@flixbus/honeycomb-react';

const FIGMA_LINK =
    'https://www.figma.com/board/HE3kUnmSUSRh2h9kd55RXC/Roll-out-plan%3A-Ride-Search-in-OneOps?node-id=0-1&p=f&t=BGn9qR94DOVT4i2N-0';
const ONE_OPS = 'https://app.oneops.flixbus.com/';
const SLRSD = 'https://flixtech.atlassian.net/servicedesk/customer/portal/12';

export default function RideSearchBanner() {
    const [showBanner, setShowBanner] = useState(true);
    return (
        <>
            {showBanner && (
                <div className="temporary-banner" appearance="warning" small>
                    <p>
                        In February 2025 this Search page will redirect to the
                        new Ride Search service in OneOps. More info{' '}
                        <Link target="_blank" href={FIGMA_LINK}>
                            here
                        </Link>
                        .
                    </p>
                    <p>
                        To prepare for this change, you can start using the{' '}
                        <Link target="_blank" href={ONE_OPS}>
                            OneOps Ride Search
                        </Link>{' '}
                        today.
                    </p>
                    <p>
                        Provide any feedback to via the team{' '}
                        <Link target="_blank" href={SLRSD}>
                            Solaris service desk
                        </Link>
                        .
                    </p>
                    <Button link onClick={() => setShowBanner(false)}>
                        <Icon InlineIcon={IconClose} />
                        Close for now
                    </Button>
                </div>
            )}
        </>
    );
}
