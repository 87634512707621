import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@flixbus/honeycomb-react';
import { Icon } from '@flixbus/honeycomb-icons-react';
import { matchGender } from './seatAssignmentUtils';

export default function SeatAssignmentDropdown(props) {
    const {
        options, // [{title: string, value: (string|number), id: string, InlineIcon:InlineIcon, description: string, group: string, ...rest}]
        groups, // [{title: string, options: options[]}]
        onSelect,
        placeholder,
        disabled,
        pax,
        selected,
        selectedList,
    } = props;
    const [active, setActive] = useState(false);
    const { title: selectedTitle, InlineIcon: SelectedInlineIcon } =
        selected || {};

    const clickOutside = useCallback(
        (e) => {
            if (active === true) {
                setActive(false);
            }
        },
        [active, setActive]
    );

    const ListItem = (option, pax, idx) => {
        const { title, InlineIcon, id, description } = option;
        const disabled = selectedList?.includes(id);
        return (
            <li
                className="seats-dropdown__list-item"
                key={pax.ticket_id + '_' + id + '_' + idx}
                onClick={() => {
                    if (!disabled) {
                        setActive(false);
                        onSelect(option);
                    }
                }}
                disabled={!matchGender(pax, option) || disabled}
            >
                <Icon InlineIcon={InlineIcon} /> {title}
                {description ? ` (${description.replace('_', '-')})` : null}
            </li>
        );
    };

    // hide panel with outside clicks
    useEffect(() => {
        function clickToClose(e) {
            const className = e.target && e.target.className;
            /**
             * @todo come up with another solutions to detect element,
             * more specific. Issues with SVGElement.
             */
            if (
                typeof className === 'string' &&
                (className.includes('hcr-popup') ||
                    className.includes('seat-assignment-dialog'))
            ) {
                clickOutside();
            }
        }
        if (active === true) {
            document.addEventListener('click', clickToClose, false);
        }
        return () => {
            document.removeEventListener('click', clickToClose, false);
        };
    }, [active, clickOutside]);

    return (
        <div className="seats-dropdown">
            {active && (
                <ul className="seats-dropdown__list">
                    {groups
                        ? groups.map((group, idx) => {
                              return (
                                  <React.Fragment
                                      key={`${group.title}-fragment`}
                                  >
                                      <li
                                          key={group.title}
                                          className="seats-dropdown__list-item--heading"
                                      >
                                          {group.title}
                                      </li>
                                      {group.options.map((option) => {
                                          return ListItem(option, pax, idx);
                                      })}
                                      {idx === 0 && (
                                          <li className="seats-dropdown__list-item--sep" />
                                      )}
                                  </React.Fragment>
                              );
                          })
                        : null}
                    {options
                        ? options.map((option, idx) =>
                              ListItem(option, pax, idx)
                          )
                        : null}
                </ul>
            )}
            <Button
                onClick={() => {
                    console.log('click:', groups, options);
                    if (groups?.length || options?.length) {
                        setActive(true);
                    }
                }}
                extraClasses="seats-dropdown__button seats-dropdown__button"
                disabled={disabled}
            >
                {selectedTitle ? (
                    <span>
                        <Icon InlineIcon={SelectedInlineIcon} />
                        {selectedTitle}
                    </span>
                ) : (
                    placeholder
                )}
            </Button>
        </div>
    );
}

SeatAssignmentDropdown.propTypes = {
    options: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
    pax: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    coachesMap: PropTypes.object,
    isCoach: PropTypes.bool,
    disabled: PropTypes.bool,
};
