import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconNewsletter,
    IconSave,
    IconPrint,
} from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../System/Translations';
import UserCan from '../../auth/UserCan';

import DataGrid from '../UI/DataGrid';
import Order from './components/Order';
import Header from './components/TableHeader';
import PopupSelectedPaxEmails from './components/PopupSelectedPaxEmails';
import Rebookr from '../Rebookr';
import EmailNotifier from '../EmailNotifier';
import SeatAssignmentDialog from '../SeatAssignmentDialog';
import PaxListPdfDialogWithIframe from '../PaxListPdfDialogWithIframe';
import Toolbar, { ToolGroup, ToolItem } from '../ToolBar';
import BatchSelectionForm from './components/BatchSelectionForm';
import TotalInfoTr from './components/TotalInfoTr';
import BusterBusRow from './components/BusterBusRow';
import useCSV from './hooks/useCSV';
import useBusterBusPax from './hooks/useBusterBusPax';
import useColumns from './hooks/useColumns';
import ColumnsPanel from './components/ColumnsControl';
import useWsStatusUpdates from './hooks/useWsStatusUpdates';
import api from '../../api/Client';

import OrdersFilters from './components/OrdersFilters/OrdersFilters';
import useOrdersFilters from './components/OrdersFilters/useOrdersFilters';
import useGetAllComments from '../CommentsHooks/useGetAllComments';
import useAttrSelection from './components/BatchSelectionForm/useAttrSelection';

export default function ReservationsTable(props) {
    const {
        orders,
        selectedPax,
        rideId,
        paxStopsFrom,
        paxStopsTo,
        rideMeta,
        printView,
        timeline,
        shops,
        resetPax,
        ordersReFetch,
    } = props;
    const translate = useContext(TranslateContext);
    const [dialogIsActive, setDialog] = useState(false);

    const {
        orders: filteredOrders,
        filter,
        addFilters,
        filters,
        reset: filtersReset,
    } = useOrdersFilters(orders);

    const [isCSVLoading, generateCSV] = useCSV(
        filteredOrders,
        rideMeta.ride.uuid,
        timeline
    );
    const [distribute] = useBusterBusPax(filteredOrders);
    const [columns, columnsFlag, saveColumns] = useColumns();
    const colSpan = Object.keys(columns).length - 1;
    const [topic, setTopic] = useState(null);
    const paxStatusUpdate = useWsStatusUpdates(topic);
    const [attr = {}, setAttr, resetAttr] = useAttrSelection();

    const paxCountFiltered = filteredOrders.reduce((acc, order) => {
        return acc + order.passengers.length;
    }, 0);
    const paxCount = orders.reduce((acc, order) => {
        return acc + order.passengers.length;
    }, 0);
    const paxIdsList = orders.reduce((acc, order) => {
        return acc.concat(order.passengers.map((p) => p.ticket_id));
    }, []);

    const {
        comments: paxComments,
        loading: paxCommentsLoading,
        getComments: getPaxComments,
    } = useGetAllComments(paxIdsList);

    useEffect(() => {
        const getConfig = async () => {
            const config = await api.getAppConfig();
            setTopic(
                `${
                    config.wamp.topic.passenger_status
                }.${rideMeta.ride.uuid.replace(/-/g, '')}`
            );
        };
        getConfig();
    }, [rideMeta.ride.uuid]);

    useEffect(() => {
        if (!paxCommentsLoading) {
            getPaxComments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // reset any pax selection when applying or reset filters
        resetPax();

        // filter the table
        filter();
    }, [filters, filter, resetPax]);

    /**
     * @TODO
     * update all those components that use selectedPax
     * with selectedPAXList to remove `orders` props dependency
     */
    /**
     * @TODO
     * move Toolbar to separate component
     */
    return (
        <div className="reservations_v2">
            <div className="timeline-toolbar-wrapper timeline-toolbar-wrapper--with-line hide-on-print">
                <Toolbar>
                    <ToolGroup>
                        <ToolItem align="left">
                            <ColumnsPanel
                                columns={columns}
                                saveColumns={saveColumns}
                                showDescription={!columnsFlag}
                            />
                        </ToolItem>
                    </ToolGroup>
                    <ToolGroup>
                        <ToolItem>
                            <PopupSelectedPaxEmails
                                orders={filteredOrders}
                                selectedPax={selectedPax}
                            />
                        </ToolItem>

                        <ToolItem>
                            <Rebookr
                                rideUuid={rideMeta.ride.uuid}
                                rideId={rideId}
                                orders={filteredOrders}
                                selectedPax={selectedPax}
                                isGhost={rideMeta.ride.status === 'ghost'}
                            />
                        </ToolItem>

                        <UserCan
                            perform="send_email"
                            yes={() => (
                                <ToolItem>
                                    <span
                                        onClick={setDialog}
                                        className="timeline-toolbar-icon-button"
                                    >
                                        <Icon
                                            appearance="primary"
                                            InlineIcon={IconNewsletter}
                                            title={translate(
                                                'reservations.table.send_notification'
                                            )}
                                        />
                                        &nbsp;
                                        {translate(
                                            'reservations.table.send_notification'
                                        )}
                                    </span>

                                    {dialogIsActive ? (
                                        <EmailNotifier
                                            rideUuid={rideMeta.ride.uuid}
                                            orders={orders}
                                            preSelectedPax={selectedPax}
                                            toggleDialog={setDialog}
                                        />
                                    ) : null}
                                </ToolItem>
                            )}
                        />

                        <ToolItem>
                            <SeatAssignmentDialog
                                rideUuid={rideMeta.ride.uuid}
                                isTr={rideMeta.should_print_pax_list}
                                selectedPax={selectedPax}
                                orders={filteredOrders}
                                ordersReFetch={ordersReFetch}
                                resetPax={resetPax}
                            />
                        </ToolItem>

                        <ToolItem>
                            <PaxListPdfDialogWithIframe
                                rideUuid={rideMeta.ride.uuid}
                            />
                        </ToolItem>

                        <ToolItem>
                            <Button
                                onClick={generateCSV}
                                link
                                loading={isCSVLoading}
                            >
                                <Icon
                                    appearance="primary"
                                    InlineIcon={IconSave}
                                />
                                CSV
                            </Button>
                        </ToolItem>

                        <ToolItem extraClasses="hide-on-print">
                            {/* @TODO change to button link */}
                            <Button
                                RouterLink={Link}
                                to="reservations/print"
                                link
                            >
                                <Icon
                                    appearance="primary"
                                    InlineIcon={IconPrint}
                                />
                                {translate('toolbar.print_view')}
                            </Button>
                        </ToolItem>
                    </ToolGroup>
                </Toolbar>
                <Toolbar>
                    <ToolGroup full>
                        <ToolItem align="left">
                            <BatchSelectionForm
                                paxStopsFrom={paxStopsFrom}
                                paxStopsTo={paxStopsTo}
                                orders={filteredOrders}
                                attr={attr}
                                onSetAttr={(code, value) => {
                                    setAttr(code, value);
                                    addFilters({ [code]: value });
                                }}
                                shops={shops}
                            />
                        </ToolItem>
                        <ToolItem align="left">
                            <OrdersFilters
                                onFilter={filter}
                                onFilterSet={addFilters}
                                filters={filters}
                                onReset={() => {
                                    resetAttr();
                                    filtersReset();
                                }}
                            />
                        </ToolItem>
                    </ToolGroup>
                </Toolbar>

                <div className="timeline-toolbar__total">
                    PAX: {paxCountFiltered} shown ({paxCount} total)
                </div>
            </div>
            {rideMeta.should_print_pax_list && (
                <TotalInfoTr orders={filteredOrders} />
            )}
            <DataGrid>
                <Header
                    {...props}
                    orders={filteredOrders}
                    printView={printView}
                    columns={columns}
                />
                {rideMeta.with_booster_buses
                    ? Object.entries(distribute()).map(([plate, value]) => (
                          <React.Fragment key={`${plate}_${value}`}>
                              <BusterBusRow plate={plate} colSpan={colSpan} />
                              {value.map((order) => (
                                  <Order
                                      {...props}
                                      key={order.id}
                                      order={order}
                                      printView={printView}
                                      columns={columns}
                                      paxComments={paxComments}
                                      paxCommentsLoading={paxCommentsLoading}
                                  />
                              ))}
                          </React.Fragment>
                      ))
                    : filteredOrders.map((order) => (
                          <Order
                              {...props}
                              key={order.id}
                              order={order}
                              printView={printView}
                              columns={columns}
                              paxStatusUpdate={paxStatusUpdate}
                              paxComments={paxComments}
                              paxCommentsLoading={paxCommentsLoading}
                          />
                      ))}
            </DataGrid>
        </div>
    );
}

ReservationsTable.propTypes = {
    orders: PropTypes.arrayOf(PropTypes.shape).isRequired,
    selectedPax: PropTypes.array,
    rideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    /**
     * list of stops unique with passengers orders
     * in timeline sequence (pass from withOrdersApi)
     */
    paxStopsFrom: PropTypes.shape(),
    paxStopsTo: PropTypes.shape(),
    /** meta data for ride passed from HOC withMetaRideConsumer */
    rideMeta: PropTypes.shape({
        from: PropTypes.shape(),
        ride: PropTypes.shape(),
        to: PropTypes.shape(),
        should_print_pax_list: PropTypes.bool,
    }).isRequired,
    stopsSequence: PropTypes.object,
    addBatch: PropTypes.func.isRequired,
    removeBatch: PropTypes.func.isRequired,
    printView: PropTypes.bool,
};
ReservationsTable.defaultProps = {
    selectedPax: [],
    paxStopsFrom: {},
    paxStopsTo: {},
    stopsSequence: {},
    printView: false,
};
