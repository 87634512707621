import React, { useContext, useEffect, useState } from 'react';
import { Button, Text } from "@flixbus/honeycomb-react";
import { TranslateContext } from "../System/Translations";
import { CUSTOMER_NOTIFIER_V2_SCOPE, getToken } from "../../auth";

const CUSTOMER_NOTIFIER_V2_PCR_URL = 'https://customer-notifier.bruteforce-prod.flixtech.io/api/custom-pcr';
const CUSTOMER_NOTIFIER_V2_MANUAL_CAMPAIGN_URL = 'https://customer-notifier-fe-ops-services-public.ew1p1.k8s.flix.tech/campaigns/manual';

const openTemplateManagerWithToken = (token) => {
    const url = `${CUSTOMER_NOTIFIER_V2_MANUAL_CAMPAIGN_URL}?token=${token}`;
    const newTab = window.open(url, '_blank');
    if (newTab) {
        newTab.focus();
    }
}

const SendCampaign = ({ selectedPax, rideUuid }) => {
    const translate = useContext(TranslateContext);
    const [token, setToken] = useState(null);

    useEffect(() => {
        console.log('token', token)
    }, [token])

    useEffect(() => {
        const requestToken = async () => {
            const token = await getToken(CUSTOMER_NOTIFIER_V2_SCOPE);
            setToken(token);
        };

        requestToken();
    }, []);

    const handleOpenWhatsappClick = () => {
        const preparedData = {
            rideId: rideUuid,
            orders: selectedPax.map(pax => String(pax)),
        }

        if(token) {
            fetch(CUSTOMER_NOTIFIER_V2_PCR_URL, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(preparedData),
            })
                .then(data => data.json())
                .then(responseData => {
                    openTemplateManagerWithToken(responseData.id);
                })
                .catch(err => {
                    console.error('Error happened while saving orders:', err);
                });
        }
    }

    return (
        <>
            <Text>{translate('send_campaign.description')}</Text>
            <Button
                disabled={!selectedPax.length}
                appearance="primary"
                onClick={handleOpenWhatsappClick}
            >
                {translate('send_campaign.cta-button')}
            </Button>
        </>
    )
}

export default SendCampaign;