import React, { useContext, useState } from 'react';
import { Button, Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconCopy, IconEdit } from '@flixbus/honeycomb-icons-react';
import useUserCan from '../../auth/useUserCan';
import CancelRideButton from './CancelRideButton';
import RideInfoTag from './RideInfoTag';
import RideInfoActions from './RideInfoActions';
import { formatDate, getUtcOffsetText } from '../../util/date';

import './RideInfo.scss';
import { NotifyContext } from '../NotificationSystem/context';
import { TranslateContext } from '../System/Translations';
import { RideStatusPopup } from '../RideStatusPopup';

export default function RideInfo({ rideMeta, pageTitle, printView }) {
    const { from, to, ride } = rideMeta;
    const notify = useContext(NotifyContext);
    const translate = useContext(TranslateContext);
    const [rideStatusPopupActive, setRideStatusPopupActive] = useState(false);

    const isUserCanChangeRideStatus = useUserCan('change_ride_status');

    const departureDate =
        formatDate.parseZone(ride.departure, 'ddd, DD MMM YYYY, HH:mm') +
        getUtcOffsetText(ride.departure);

    const lineType = ride.line
        ? ride.line.line_types.map((item) => item.name)
        : [];

    const contractModels = {
        S: 'Cooperation line S',
        M: 'Cooperation line M',
        L: 'Franchise Line L',
        XL: 'Franchise Line XL',
    };
    const contractModel = ride.line
        ? ride.line.contract_model && contractModels[ride.line.contract_model]
        : null;

    pageTitle.set(
        `${ride.uid} | ${from.short_name} - ${to.short_name} | ${departureDate}`
    );

    const isUserCanCancel = useUserCan('ride_cancelation');

    const statusesLabels = {
        on_sale: 'On sale',
        hidden: 'Hidden',
        ghost: 'Ghost',
        archived: 'Archived',
        drafted: 'Drafted',
        planr_drafted: 'Planr drafted',
        preview: 'Preview',
    };
    function copyToClipboard(info) {
        try {
            const { clipboard } = window.navigator;
            clipboard.writeText(info).then((e) => {
                notify({
                    message: `${translate('clipboard.copied')}: ${info}`,
                    type: 'success',
                });
            });
        } catch (error) {
            notify({
                type: 'danger',
                message: `${translate('clipboard.error')} (${error})`,
            });
        }
    }

    const UID = ride.uid.replace('L', '').replace('TN', '');

    return (
        <>
            <header className="ride-info">
                <div className="ride-info__left">
                    <div
                        className="ride-info__del ride-info__del--fixed"
                        onClick={() => {
                            const clipText = `${ride.uid} | ${
                                from.short_name
                            } → ${to.short_name} | ${departureDate} ${
                                ride.id ? `| # ${ride.id}` : ''
                            }`;
                            copyToClipboard(clipText);
                        }}
                    >
                        <div className="ride-info__main-info ride-info__main-info--title">
                            <div className="ride-info__main-info__uid">
                                {UID}
                            </div>
                            <div className="ride-info__main-info__id">
                                {ride.id ? `ID ${ride.id}` : null}
                            </div>
                            <div className="ride-info__copy-button">
                                <Tooltip
                                    content="Copy to clipboard"
                                    active
                                    position="top"
                                    size="content-fit"
                                    id="Copy-to-clipboard"
                                >
                                    <Button
                                        link
                                        aria-label="copy to clipboard"
                                        display="square"
                                    >
                                        <Icon InlineIcon={IconCopy} />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="ride-info__secondary-info ride-info__secondary-info--title">
                            {ride.uuid}
                        </div>
                    </div>

                    <div className="ride-info__line-wrapper">
                        <div className="ride-info__main-info">
                            {departureDate}
                        </div>
                        <div className="ride-info__secondary-info">
                            {from.short_name} → {to.short_name}
                        </div>
                    </div>
                </div>

                <div className="ride-info__right">
                    <div className="content-box content-box--align-center ride-info-tags">
                        <div className="ride-info__del">
                            <div className="ride-info__secondary-info">
                                <RideInfoTag>{contractModel}</RideInfoTag>
                            </div>
                            <div className="ride-info__secondary-info">
                                {lineType.length > 0 && (
                                    <RideInfoTag>
                                        {lineType.join(', ')}
                                    </RideInfoTag>
                                )}
                            </div>
                        </div>
                        <div className="ride-info__del">
                            {isUserCanChangeRideStatus ? (
                                <RideInfoTag
                                    onClick={() =>
                                        setRideStatusPopupActive(true)
                                    }
                                >
                                    {statusesLabels[ride.status]}
                                    <Icon
                                        size={2}
                                        extraClasses="ride-info__edit-icon"
                                        InlineIcon={IconEdit}
                                    />
                                </RideInfoTag>
                            ) : (
                                <RideInfoTag>
                                    {statusesLabels[ride.status]}
                                </RideInfoTag>
                            )}
                        </div>
                    </div>

                    {!printView && (
                        <div className="content-box content-box--space-between content-box--align-center hide-on-print">
                            {isUserCanCancel && <CancelRideButton />}

                            <div className="ride-info__actions">
                                <RideInfoActions rideUuid={ride.uuid} />
                            </div>
                        </div>
                    )}
                </div>
            </header>

            <RideStatusPopup
                rides={[ride.uuid]}
                isOpened={rideStatusPopupActive}
                handleClose={() => setRideStatusPopupActive(false)}
            />
        </>
    );
}
