import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Icon,
    IconClose,
    IconTrainWagon,
} from '@flixbus/honeycomb-icons-react';
import SeatAssignmentDropdown from './SeatAssignmentDropdown';
import { sortTopByScore } from './seatAssignmentUtils';

const SeatsList = ({
    seats,
    pax,
    onSelect,
    onReset,
    disabled,
    selected,
    selectedList,
}) => {
    const seatsScored = sortTopByScore(seats);

    const seatsGrouped =
        seatsScored.length > 0
            ? [
                  {
                      title: 'Recommended seats:',
                      options: seatsScored.slice(0, 3),
                  },
                  {
                      title: 'Other seats:',
                      options: seatsScored.slice(3),
                  },
              ]
            : [];

    function reset() {
        onReset();
        onSelect(null);
    }

    return (
        <>
            <SeatAssignmentDropdown
                groups={seatsGrouped}
                onSelect={(option) => {
                    if (disabled) return;
                    onSelect(option);
                }}
                pax={pax}
                placeholder="Select seat"
                selected={selected}
                disabled={disabled}
                selectedList={selectedList}
            />
            {selected?.title && (
                <Icon
                    extraClasses="seat-assignment-dialog__seats-remove"
                    InlineIcon={IconClose}
                    onClick={reset}
                />
            )}
        </>
    );
};

const SeatsAndCoaches = ({
    coaches,
    coachesMap,
    pax,
    onSelect,
    disabled,
    selectedList,
}) => {
    const [coach, setCoach] = useState();
    const [seat, setSeatsOptions] = useState([]);
    const coachOptions = Array.from(coachesMap)
        .map(([key, value]) => ({
            title: key,
            value: value[0].deck,
            InlineIcon: IconTrainWagon,
        }))
        .sort((a, b) => a.value - b.value);

    function onCoachSelect(coachOption) {
        setCoach(coachOption);
        setSeatsOptions(null);
    }

    function onSeatSelect(seatOption) {
        setSeatsOptions(seatOption);
        onSelect(seatOption);
    }

    function onReset() {
        setCoach(null);
        setSeatsOptions(null);
        onSelect(null);
    }

    return (
        <>
            <SeatAssignmentDropdown
                options={coachOptions}
                onSelect={onCoachSelect}
                placeholder="Select coach"
                coachesMap={coachesMap}
                pax={pax}
                selected={coach}
                disabled={disabled}
            />
            <SeatsList
                seats={coachesMap.get(coach?.title) || []}
                pax={pax}
                onSelect={onSeatSelect}
                selected={seat}
                disabled={disabled}
                onReset={onReset}
                selectedList={selectedList}
            />
        </>
    );
};

export { SeatsAndCoaches, SeatsList };

SeatsAndCoaches.propTypes = {
    coaches: PropTypes.shape({
        [PropTypes.number]: PropTypes.array,
    }).isRequired,
    coachesMap: PropTypes.object.isRequired,
    pax: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
SeatsList.propTypes = {
    seats: PropTypes.array,
    pax: PropTypes.object.isRequired,
    selectedDeck: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    resetCoach: PropTypes.func,
    disabled: PropTypes.bool,
};
