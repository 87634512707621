import { useContext, useState, useEffect } from 'react';
import { TranslateContext } from '../System/Translations';
import { NotifyContext } from '../NotificationSystem/context';
import apiRebookr from '../../api/RebookrApi';

const CANCEL_TOKEN = 'useSeatReassignment';
const SUPPORTED_CATEGORIES = ['empty_seat', 'premium_seat'];

export default function useSeatReassignment({
    rideUuid,
    orderId,
    isTr,
    isBus,
    ordersRefetch,
    close,
}) {
    const t = useContext(TranslateContext);
    const notify = useContext(NotifyContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        return () => {
            apiRebookr.cancel(CANCEL_TOKEN);
        };
    }, []);

    function seatReassign(seatSelection, reservations, segment) {
        let reassignment = [];

        Object.entries(seatSelection).forEach(([ticketId, seatOption]) => {
            const { id, label, category, vehicleLabel } = seatOption;
            reassignment.push({
                orderId,
                rideUuid,
                fromUuid: segment.fromUuid,
                toUuid: segment.toUuid,
                ticketUuid: ticketId,
                seatReservationProductUuid:
                    reservations?.find((r) => r.ticket_id === ticketId)
                        ?.seat_reservation_product_uuid || null,
                newSeatUuid: id,
                label,
                coach: isBus ? null : vehicleLabel, // for bus we pass null always
                seatCategory: SUPPORTED_CATEGORIES.includes(category)
                    ? category
                    : isTr
                    ? SUPPORTED_CATEGORIES[1]
                    : SUPPORTED_CATEGORIES[0], // For TR rides default supported category is premium_seat
            });
        });

        if (reassignment.length > 0) {
            setIsLoading(true);
            apiRebookr
                .post(`api/seat/reassignment`, reassignment, {
                    cancelTokenId: CANCEL_TOKEN,
                })
                .then((res) => {
                    if (res.body.length > 0) {
                        res.body.forEach((item) => {
                            if (item.status !== 'OK') {
                                const message =
                                    item.message || t('common.error');
                                notify({
                                    type: 'danger',
                                    message: message,
                                });
                                setError(message);
                                return;
                            }
                            notify({
                                type: 'success',
                                message: t(
                                    'reservations.table.seat_reassignment_success'
                                ),
                            });
                        });
                    }

                    setIsLoading(false);
                    ordersRefetch(600);
                    close();
                })
                .catch((e) => {
                    const message =
                        e?.response?.data?.error ||
                        e?.response?.data?.errorMsg ||
                        e?.message ||
                        String(e) ||
                        t('common.error');
                    setIsLoading(false);
                    notify({
                        type: 'danger',
                        message,
                    });
                    setError(message);
                });
        }
    }
    return {
        isLoading,
        error,
        seatReassign,
    };
}
