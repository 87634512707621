import React, { useState } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { IconArrowLeft } from '@flixbus/honeycomb-icons-react';
import ContentBox from '../ContentBox';

import TimelineSE from '../Timeline_';
import Comments from '../Comments';
import ReservationsV2 from '../ReservationsTableV2';
import NotificationsLog from '../NotificationsLog';
import ActionsLog from '../ActionsLog';
import RideCapacity from '../RideCapacity';
import withTimelineApi from './hocs/withTimelineApi';
import withNotifyConsumer from '../NotificationSystem/context/hocs/withNotifyConsumer';
import SideBarControls from '../SideBarControls';
import SideTimeline from '../SideTimeline/SideTimeline';
import ActionLoggerBeta from '../ActionLogger';
import NotificationsLogBeta from '../NotificationsLogBeta';
import usePIICompliance from '../../auth/usePIICompliance';

/**
 * @TODO
 * remove dependency from match(routing thing) prop
 */
function RideViewInfo(props) {
    const {
        match,
        meta,
        rideId,
        rideUuid,
        rideIsDelayProcessEnabled,
        rideStatus,
        withBoosterBuses,
        pax2busDistributionEnabled,
        timeline,
        saveTimelineDelays,
        updateTimeline,
        fetchTimeline,
        sendPaxRightsNotification,
        printView,
        rideType,
    } = props;
    const stopsSequence = timeline.reduce((acc, item) => {
        const result = { ...acc };
        if (item.entry_type === 'stop') {
            result[item.code] = {
                sequence: item.sequence,
                uuid: item.uuid,
                name: item.name,
            };
        }
        return result;
    }, {});

    const [sideTimelineOpen, setSideTimeline] = useState(false);
    const isPII = usePIICompliance({
        isTurkey: meta.should_print_pax_list,
    });

    const handleSideStationClick = (code) => () => {
        const station = document.getElementById(code);
        const scrollLeftTo = station?.offsetLeft;

        if (scrollLeftTo) {
            document.getElementById('scroll-area').scrollTo({
                top: 0,
                left: scrollLeftTo,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className="ride-view__context-block">
            <div className="content-box">
                <div className="content-box-components">
                    <ContentBox rideId={rideId} path={`${match.path}/:alias`}>
                        <TimelineSE
                            timeline={timeline}
                            alias="timeline"
                            rideId={rideId}
                            rideUuid={rideUuid}
                            rideIsDelayProcessEnabled={
                                rideIsDelayProcessEnabled
                            }
                            sendPaxRightsNotification={
                                sendPaxRightsNotification
                            }
                            rideStatus={rideStatus}
                            withBoosterBuses={withBoosterBuses}
                            pax2busDistributionEnabled={
                                pax2busDistributionEnabled
                            }
                            updateTimeline={updateTimeline}
                            fetchTimeline={fetchTimeline}
                            saveTimelineDelays={saveTimelineDelays}
                            rideType={rideType}
                        />
                        {isPII ? (
                            <ReservationsV2
                                stopsSequence={stopsSequence}
                                rideId={rideId}
                                rideUuid={rideUuid}
                                printView={printView}
                                alias="reservations"
                                timeline={timeline}
                            />
                        ) : null}
                        {isPII ? (
                            <Comments
                                rideId={rideId}
                                rideUuid={rideUuid}
                                alias="comments"
                            />
                        ) : null}
                        {isPII ? (
                            <NotificationsLog
                                rideId={rideId}
                                rideUuid={rideUuid}
                                alias="notifications"
                            />
                        ) : null}
                        {isPII ? (
                            <ActionsLog
                                rideId={rideId}
                                rideUuid={rideUuid}
                                printView={printView}
                                alias="actions-log"
                            />
                        ) : null}
                        {isPII ? (
                            <ActionLoggerBeta
                                alias="actions-log-beta"
                                rideId={rideUuid}
                            />
                        ) : null}
                        {isPII ? (
                            <NotificationsLogBeta
                                alias="notifications-log-beta"
                                rideId={rideUuid}
                            />
                        ) : null}
                        <RideCapacity
                            meta={meta}
                            timeline={timeline}
                            fetchTimeline={fetchTimeline}
                            rideStatus={rideStatus}
                            alias="capacity"
                        />
                    </ContentBox>
                </div>
            </div>
            <SideBarControls
                items={[
                    {
                        onClick: () => {
                            setSideTimeline(true);
                        },
                        icon: IconArrowLeft,
                        key: 1,
                    },
                ]}
            />
            <SideTimeline
                timeline={timeline}
                active={sideTimelineOpen}
                ride={meta.ride}
                onClose={() => {
                    setSideTimeline(false);
                }}
                onStationClick={handleSideStationClick}
            />
        </div>
    );
}

RideViewInfo.propTypes = {
    match: PropTypes.shape({ url: PropTypes.string, path: PropTypes.string })
        .isRequired,
    meta: PropTypes.object.isRequired,
    rideUuid: PropTypes.string.isRequired,
    rideIsDelayProcessEnabled: PropTypes.bool,
    rideStatus: PropTypes.string.isRequired,
    // from withTimelineApi HOC
    timeline: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    saveTimelineDelays: PropTypes.func.isRequired,
    updateTimeline: PropTypes.func.isRequired,
    fetchTimeline: PropTypes.func.isRequired,
    sendPaxRightsNotification: PropTypes.func.isRequired,
    printView: PropTypes.bool,
    withBoosterBuses: PropTypes.bool.isRequired,
    pax2busDistributionEnabled: PropTypes.bool.isRequired,
};

RideViewInfo.defaultProps = {
    meta: {},
    rideIsDelayProcessEnabled: false,
    rideStatus: '',
    printView: false,
    withBoosterBuses: false,
    pax2busDistributionEnabled: false,
};

export default compose(withNotifyConsumer, withTimelineApi)(RideViewInfo);
