import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Heading, Text, Spinner } from '@flixbus/honeycomb-react';
import api from '../../api/Client';
import { formatDate, getUtcOffsetText } from '../../util/date';
import localStorage from '../../util/localStorage';
import CapacityTable from './CapacityTable/';
import { TranslateContext } from '../System/Translations';

import './styles.scss';

function getBatchIds() {
    const { ids, expire } =
        JSON.parse(localStorage.getItem(localStorage.BATCH_CAPACITY)) || {};
    if (expire && expire <= new Date().getTime()) {
        localStorage.removeItem(localStorage.BATCH_CAPACITY);
        return undefined;
    }
    return ids;
}

function chunkArray(array, size) {
    let chunked = [];
    if (size >= array.length) {
        chunked.push(array);
        return chunked;
    }
    for (let i = 0; i < Math.ceil(array.length / size); i++) {
        chunked.push(array.slice(size * i, size * i + size));
    }
    return chunked;
}

export default function RideCapacity(props) {
    const { meta, notify } = props;
    const translate = useContext(TranslateContext);
    const [loading, setLoading] = useState(false);
    const [rides, setRides] = useState([]);
    const ids = Object.keys(meta).length === 0 ? getBatchIds() : undefined;

    useEffect(() => {
        if (ids) {
            setLoading(true);

            const idsSet = ids.split(',');
            const chunkedIds = chunkArray(idsSet, 20);
            const requests = chunkedIds.map((set) => {
                return api.get(`/ride/${set.join(',')}`);
            });

            Promise.all(requests)
                .then((res) => {
                    const values = res.map((r) =>
                        Object.values(r.result).map((value) => value)
                    );
                    return values.reduce((acc, value) => acc.concat(value), []);
                })
                .then((rides) => {
                    if (rides.length) {
                        setRides(rides);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    if (!api.isCancel(error)) {
                        setLoading(false);
                        notify({
                            message: `${error.toString()}`,
                            type: 'danger',
                        });
                    }
                });
        } else {
            if (Object.keys(meta).length > 0) {
                setRides([meta]);
            }
        }
    }, [ids, meta, notify]);
    return (
        <div className="ride-capacity">
            <div className="ride-view__context-block--narrow">
                {loading && (
                    <div aria-live="polite" aria-busy="true">
                        <Spinner />
                    </div>
                )}
                {!loading && (
                    <>
                        {rides.map((item) => {
                            const { ride } = item;
                            const { line_id } = ride?.line_variation || {};
                            const { from, to } = item;
                            const departureDate =
                                formatDate.parseZone(
                                    ride.departure,
                                    'ddd, DD MMM YYYY - HH:mm' // ddd, DD MMM YYYY,
                                ) + getUtcOffsetText(ride.departure);
                            return (
                                <div key={`table-${ride.id}`}>
                                    <Heading size={2}>
                                        {translate('capacity.line')} {line_id}{' '}
                                        {from.name} → {to.name}
                                    </Heading>
                                    {/*
                                        Temporary Hidden
                                        <RidesPicker rides={rides} />
                                    */}
                                    <CapacityTable
                                        rideId={ride.id}
                                        rideUuid={ride.uuid}
                                        departureDate={departureDate}
                                        notify={notify}
                                        rideStatus={ride.status}
                                        translate={translate}
                                    />
                                </div>
                            );
                        })}
                        {rides.length === 0 && (
                            <Text>
                                There is no rides info. Try to back on a search
                                page and select rides again.
                            </Text>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

RideCapacity.propTypes = {
    meta: PropTypes.object.isRequired,
};

RideCapacity.defaultProps = {
    meta: {},
};
