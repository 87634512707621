import { useEffect, useState } from 'react';
import api from '../../api/PaxBankApi';
import { seatsNormalizer, seatsSort } from './seatAssignmentUtils';

const CANCEL_TOKEN_ID = 'useSeatAvailable';

export default function useSeatAvailable(rideUuid, orderId) {
    const url = `api/rideviewer/ride/${rideUuid}/reservations/availableSeats/${orderId}`;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [coachesMap, setCoachesMap] = useState(null);
    const [reservations, setReservations] = useState(null);
    const [segment, setSegment] = useState(null);
    const [isBus, setIsBus] = useState(null);

    useEffect(() => {
        return () => {
            api.cancel(CANCEL_TOKEN_ID);
        };
    }, []);

    const getSeatAvailable = async () => {
        try {
            setIsLoading(true);
            let groupByVehicleLabel = new Map();
            const response = await api.get(url, {
                cancelTokenId: CANCEL_TOKEN_ID,
            });
            const seatsAvailableList = response.seat_availability_list
                .map((s) => seatsNormalizer(s))
                .sort(seatsSort);
            seatsAvailableList.forEach((seat) => {
                if (groupByVehicleLabel.get(seat.vehicleLabel)) {
                    groupByVehicleLabel.set(
                        seat.vehicleLabel,
                        groupByVehicleLabel.get(seat.vehicleLabel).concat(seat)
                    );
                } else {
                    groupByVehicleLabel.set(seat.vehicleLabel, [seat]);
                }
            });
            setCoachesMap(groupByVehicleLabel);
            setReservations(response.seat_reservations);
            setIsBus(
                groupByVehicleLabel.size === 1 && groupByVehicleLabel.has('0')
            );
            setSegment({
                fromUuid: response.from_uuid,
                toUuid: response.to_uuid,
            });
        } catch (error) {
            if (api.isCancel(error)) {
                return;
            }
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        coachesMap,
        reservations,
        segment,
        isLoading,
        isBus,
        error,
        getSeatAvailable,
    };
}
