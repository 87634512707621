import React, { Component } from 'react';
import { Box, Infobox } from '@flixbus/honeycomb-react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error('ErrorBoundary caught an error', error, errorInfo);
        this.setState({ hasError: true, error });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            const { render } = this.props;
            return render ? (
                <render error={this.state.error} />
            ) : (
                <Box>
                    <Infobox appearance="danger">
                        {this.state.error?.toString()}
                    </Infobox>
                </Box>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
