import React, { useContext, useState } from 'react';
import { Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconSeatFree } from '@flixbus/honeycomb-icons-react';

import { TranslateContext } from '../System/Translations';

import './SeatAssignmentDialog.scss';
import SeatAssignmentPopUp from './SeatAssignmentPopUp';

export default function SeatAssignmentDialog(props) {
    const { rideUuid, selectedPax, orders, ordersReFetch, resetPax, isTr } =
        props;

    const t = useContext(TranslateContext);
    const isOnlyOnePaxSelected = selectedPax ? selectedPax.length === 1 : false;

    const [isOpen, setOpen] = useState(false);

    function onClose() {
        setOpen(false);
        resetPax();
    }

    const ConditionalWrapper = ({ condition, wrapper, children }) =>
        condition ? wrapper(children) : children;

    return (
        <React.Fragment>
            <span
                className={'timeline-toolbar-icon-button'}
                onClick={() => {
                    if (isOnlyOnePaxSelected) {
                        setOpen(true);
                    }
                }}
            >
                <ConditionalWrapper
                    condition={
                        selectedPax.length <= 0 || selectedPax.length > 1
                    }
                    wrapper={(children) => (
                        <Tooltip
                            id="seat_assignment__tooltip"
                            content={
                                selectedPax?.length === 0
                                    ? t('reservations.table.select_pax')
                                    : selectedPax.length > 1
                                    ? t(
                                          'reservations.table.seat_assignment_only_one'
                                      )
                                    : null
                            }
                            hasClose
                            position={'bottom'}
                            openOnHover={true}
                            closeBtn={{ 'aria-label': 'Close me' }}
                        >
                            {children}
                        </Tooltip>
                    )}
                >
                    <Icon
                        appearance="primary"
                        InlineIcon={IconSeatFree}
                        title={t('reservations.table.seat_assignment')}
                    />
                    &nbsp;
                    <span>{t('reservations.table.seat_assignment')}</span>
                </ConditionalWrapper>
            </span>
            {isOpen && (
                <SeatAssignmentPopUp
                    rideUuid={rideUuid}
                    orderId={selectedPax[0]}
                    passengers={
                        orders.find((order) => order.id === selectedPax[0])
                            ?.passengers
                    }
                    onClose={onClose}
                    isTr={isTr}
                    ordersRefetch={ordersReFetch}
                />
            )}
        </React.Fragment>
    );
}
