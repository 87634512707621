import {
    IconBedSeat,
    IconSeat,
    IconStar,
} from '@flixbus/honeycomb-icons-react';

export const SORT_BY_SCOPE_COUNT = 3;

export const SPECIAL_CATS = [
    'panorama_seat',
    'premium_seat',
    'semi_bed_seat',
    'bed_seat',
    'free_bed_seat',
];

export const ICONS_MAP = {
    panorama_seat: IconStar,
    premium_seat: IconStar,
    semi_bed_seat: IconBedSeat,
    bed_seat: IconBedSeat,
    free_bed_seat: IconBedSeat,
    table_seat: IconSeat,
    free_seat: IconSeat,
    single_seat: IconSeat,
    front_seat: IconSeat,
    train_panorama_seat: IconSeat,
    train_premium_seat: IconSeat,
    train_comfort_seat: IconSeat,
    train_table_seat: IconSeat,
};
// make flat structure of seats properties
export function seatsNormalizer(seat) {
    return {
        label: seat.seat_details.label,
        title: seat.seat_details.label,
        id: seat.seat_id,
        category: seat.seat_details.category,
        gender: seat.gender_allowance,
        position: seat.seat_details.position,
        deck: seat.seat_details.position.deck,
        score: seat.score,
        vehicleLabel: seat.seat_details.vehicleLabel,
        InlineIcon: ICONS_MAP[seat.seat_details.category],
        description: SPECIAL_CATS.includes(seat.seat_details.category)
            ? seat.seat_details.category
            : undefined,
    };
}

// sort seats by label in rows and columns
export function seatsSort(a, b) {
    // Extract numeric part
    const numA = parseInt(a.label);
    const numB = parseInt(b.label);

    // Extract alphabetical part
    const alphaA = a.label.replace(/[0-9]/g, '');
    const alphaB = b.label.replace(/[0-9]/g, '');

    if (numA !== numB) {
        return numA - numB;
    }

    if (alphaA !== alphaB) {
        return alphaA.localeCompare(alphaB);
    }

    return 0;
}

// for gender availability filter

export const matchGender = (pax, seat) => {
    return pax.gender
        ? seat.gender.some(
              (item) => item.toLowerCase() === pax?.gender?.toLowerCase()
          )
        : true;
};

export function sortTopByScore(arr, amount = SORT_BY_SCOPE_COUNT) {
    const sorted = [...arr].sort((a, b) => b.score - a.score);
    const topByAmount = sorted.slice(0, amount);

    // Combine the top "amount" items with the rest of the original array
    const rest = arr.filter((item) => !topByAmount.includes(item));

    return [...topByAmount, ...rest];
}
