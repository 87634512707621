import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/Client';
import { Box, Skeleton, Text } from '@flixbus/honeycomb-react';

import { TranslateContext } from '../../System/Translations';

export default function withTimelineApi(Component) {
    return class WithTimelineApi extends React.Component {
        static propTypes = {
            rideUuid: PropTypes.string.isRequired,
            notify: PropTypes.func,
        };

        static defaultProps = {
            notify: () => {},
        };

        static contextType = TranslateContext;

        constructor(props) {
            super(props);
            this.state.language = props.translate.language;
        }

        state = {
            timeline: [],
            loading: true,
        };

        componentDidMount() {
            this.fetchTimeline();
        }

        componentDidUpdate() {
            const { translate } = this.props;
            const { timeline, language } = this.state;
            if (timeline.length > 0 && translate.language !== language) {
                this.setState({ language: translate.language });
                this.fetchTimeline();
            }
        }

        componentWillUnmount() {
            const { rideUuid } = this.props;
            api.cancel(`time-line-request-${rideUuid}`);
            api.cancel(`save-had-${rideUuid}`);
        }

        fetchTimeline = (success, error) => {
            const { rideUuid, notify } = this.props;
            const translate = this.context;
            api.get(`/ride/v2/${rideUuid}/timeline`, {
                cancelTokenId: `time-line-request-${rideUuid}`,
                params: {
                    locale: translate.language,
                },
            })
                .then(({ timeline }) => {
                    if (typeof success === 'function') {
                        success(timeline);
                    } else {
                        this.setState({ timeline, loading: false });
                    }
                })
                .catch((thrown) => {
                    if (!api.isCancel(thrown)) {
                        if (typeof error === 'function') {
                            error(thrown);
                        } else {
                            notify({
                                type: 'danger',
                                message:
                                    'Timeline: Error acquire, data has not been loaded.',
                            });
                            console.error(thrown);
                            this.setState({ loading: false });
                        }
                    }
                });
        };

        /**
         * updates timeline entries with given data
         * @method updateTimeline
         * @param {object[]} entries - Timeline entries to update,
         * @param {string} entries[].entry_type - entry type 'stop' or 'segment'
         * @param {string} entries[].id - id for stop or segment
         */
        updateTimeline = (entries) => {
            const { timeline } = this.state;
            let bufferEntries = [...entries];
            const newTimeline = timeline.reduce((prev, entry) => {
                const items = [...prev];
                let bufferItem = { ...entry };
                bufferEntries = bufferEntries.filter((item) => {
                    let flag = true;
                    if (
                        item.entry_type === entry.entry_type &&
                        item.id === entry.id
                    ) {
                        bufferItem = { ...bufferItem, ...item };
                        flag = false;
                    }
                    return flag;
                });
                items.push(bufferItem);
                return items;
            }, []);
            this.setState({ timeline: newTimeline });
        };

        saveTimelineDelays = (hadCollection, hadSegmentsCollection, remove) => {
            const hadChanges = {};
            const { timeline } = this.state;

            if (remove) {
                Object.keys(hadSegmentsCollection).forEach((key) => {
                    const { arrival_delay, departure_delay } =
                        hadSegmentsCollection[key];
                    if (arrival_delay !== null || departure_delay !== null) {
                        hadChanges[key] = {};
                        if (hadSegmentsCollection[key].arrival_delay !== null) {
                            hadChanges[key].departure_delay = 0;
                        }
                        if (
                            hadSegmentsCollection[key].departure_delay !== null
                        ) {
                            hadChanges[key].arrival_delay = 0;
                        }
                    }
                });
            } else {
                timeline.reduce((previous, item, index, array) => {
                    if (item.entry_type === 'segment') {
                        const previousDepartureDelay =
                            previous != null
                                ? hadCollection[previous.id].departureDelay / 60
                                : 0;
                        const nextArrivalDelay =
                            typeof array[index + 1] !== 'undefined'
                                ? hadCollection[array[index + 1].id]
                                      .arrivalDelay / 60
                                : 0;

                        // apply only those who actually have a delay
                        if (
                            previousDepartureDelay !== 0 ||
                            nextArrivalDelay !== 0
                        ) {
                            hadChanges[item.id] = {};
                            if (nextArrivalDelay)
                                hadChanges[item.id].arrival_delay =
                                    nextArrivalDelay;
                            if (previousDepartureDelay)
                                hadChanges[item.id].departure_delay =
                                    previousDepartureDelay;
                        }
                    }

                    return item;
                }, null);
            }

            const { rideUuid, notify } = this.props;

            return api
                .post(
                    `ride/v2/${rideUuid}/save-had`,
                    { confirmed_delay_time_set: hadChanges },
                    { cancelTokenId: `save-had-${rideUuid}` }
                )
                .then((response) => {
                    timeline.forEach((item, index) => {
                        if (undefined === hadCollection[item.id]) {
                            return;
                        }
                        const { arrivalDelay, departureDelay } =
                            hadCollection[item.id];

                        if (arrivalDelay)
                            timeline[index].arrival_delay = arrivalDelay;
                        if (departureDelay)
                            timeline[index].departure_delay = departureDelay;
                    });

                    notify({
                        type: 'success',
                        message: 'HAD: Delay was set.',
                    });
                    this.setState({ timeline });
                    return response;
                })
                .catch((error) => {
                    if (!api.isCancel(error)) {
                        notify({
                            type: 'danger',
                            message: 'HAD: Error while setting delay.',
                        });
                        throw error;
                    }
                });
        };

        sendPaxRightsNotification = (segmentIds, segmentsDelays) => {
            const { rideUuid, notify } = this.props;
            return api
                .post('/big-delay/send-pax-rights', {
                    rideUuid,
                    segmentIds,
                    segmentsDelays,
                })
                .then((res) => {
                    let message;
                    let type;
                    if (res.success) {
                        type = 'success';
                        message = 'HAD: Pax rights messages published';
                    } else {
                        type = 'error';
                        message = `HAD: Pax rights messages for segments ${res.failed.join(
                            ','
                        )} doesn't published`;
                    }
                    notify({
                        type,
                        message,
                    });
                })
                .catch((error) => {
                    if (!api.isCancel(error)) {
                        notify({
                            type: 'danger',
                            message: 'HAD: Pax rights not sent',
                        });
                    }
                });
        };

        render() {
            const { loading, timeline } = this.state;
            const translate = this.context;

            if (loading) {
                return (
                    <Box aria-live="polite" aria-busy="true">
                        <Text>
                            {translate('with-timeline-api.timeline-is-loading')}
                        </Text>
                        <Skeleton width="md" height="lg" />
                        <Skeleton Elem="div" width="lg" height="sm" />
                        <Skeleton Elem="div" height="sm" />
                        <Skeleton Elem="div" width="sm" height="sm" />
                        <Skeleton inline round width="xs" height="md" />
                    </Box>
                );
            }
            if (!loading && timeline.length === 0) {
                return (
                    <Box>
                        {translate(
                            'with-timeline-api.timeline-has-not-loading'
                        )}
                    </Box>
                );
            }
            return (
                <Component
                    {...this.props}
                    timeline={timeline}
                    saveTimelineDelays={this.saveTimelineDelays}
                    updateTimeline={this.updateTimeline}
                    fetchTimeline={this.fetchTimeline}
                    sendPaxRightsNotification={this.sendPaxRightsNotification}
                />
            );
        }
    };
}
