import { datadogRum } from '@datadog/browser-rum';

/**
 *
 * @param {object} context action data
 * @param {string} context.id pax ticket id
 * @param {string} context.time action timestamp
 */
export function paxCheckInAction(context) {
    datadogRum.addAction('pax-check-in', context);
}

/**
 *
 * @param {object} context action data
 * @param {string} context.id pax ticket id
 * @param {string} context.time action timestamp
 */
export function paxCheckInConfirmationAction(context) {
    datadogRum.addAction('pax-check-in-confirmation', context);
}

/**
 *
 * @param {object} context action data
 * @param {string} context.id pax ticket id
 * @param {string} context.time action timestamp
 * @param {string} context.reason fail reason
 */
export function paxCheckInFailAction(context) {
    datadogRum.addAction('pax-check-in-fail', context);
}

/**
 *
 * @param {object} context action data
 * @param {string} context.id stop id
 * @param {string} context.timestamp action timestamp
 * @param {string} context.status stop new status
 */
export function stopStatusChangeAction(context) {
    datadogRum.addAction('stop-status-change', context);
}

/**
 *
 * @param {object} context action data
 * @param {string} context.id stop id
 * @param {string} context.timestamp action timestamp
 * @param {string} context.status new status
 */
export function stopStatusChangeConfirmAction(context) {
    datadogRum.addAction('stop-status-change-confirm', context);
}

/**
 *
 * @param {object} context action data
 * @param {string} context.id stop id
 * @param {string} context.timestamp action timestamp
 * @param {string} context.reason fail reason
 */
export function stopStatusChangeFailAction(context) {
    datadogRum.addAction('stop-status-change-fail', context);
}

/**
 *
 * @param {object} context action data
 * @param {string} context.path current url path
 * @param {number} context.timestamp action timestamp
 */
export function scrollToTopAction(context) {
    datadogRum.addAction('scroll-to-top', context);
}

/**
 *
 * @param {object} context action data
 * @param {number} context.resultsShown total count of shown search items
 * @param {number} context.timestamp action timestamp
 */
export function openSearchPanelAction(context) {
    datadogRum.addAction('search-panel-open', context);
}

/**
 *
 * @param {object} context action data
 * @param {number} context.resultsShown total count of shown search items
 * @param {number} context.timestamp action timestamp
 */
export function searchPanelShownAction(context) {
    datadogRum.addAction('search-panel-shown', context);
}

export function pIIRestrictedDDAction() {
    datadogRum.addAction('pii-restricted');
}

/**
 * action to track session that starts with legacy ID
 * @param {object} context action data
 * @param {number} context.legacyRideId legacy ride id
 */
export function legacyIdRedirectAction(legacyRideId) {
    datadogRum.addAction('legacy-id-redirect', { legacyRideId });
}
