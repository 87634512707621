import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Skeleton, Grid, GridCol } from '@flixbus/honeycomb-react';
import api from '../../../api/Client';
import { DispatcherContext } from '../../System/Dispatcher';
import { legacyIdRedirectAction } from '../../../DataDog/actions';

export default function withRideMeta(Component) {
    return class WithRideMeta extends React.Component {
        static propTypes = {
            match: PropTypes.object,
            rideId: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
                PropTypes.bool,
            ]),
            notify: PropTypes.func,
            translate: PropTypes.func,
        };

        static defaultProps = {
            match: {},
            rideId: false,
            notify: () => {},
            translate: (key) => key,
        };

        static contextType = DispatcherContext;

        state = {
            isLoading: true,
            notFound: false,
            meta: {
                ride: {},
                from: {},
                to: {},
            },
        };

        componentDidMount() {
            this.fetchRideInfo();
        }

        componentDidUpdate(prevProps, prevState) {
            const { match, rideId } = prevProps;
            const { meta } = this.state;
            const { ride } = meta;
            const prevRideId =
                rideId || (match && match.params && match.params.id);

            if (prevRideId !== this.rideId) {
                this.fetchRideInfo();
            }
            if (ride.id !== prevState.meta.ride.id) {
                this.fetchPaxCount();
            }
        }

        componentWillUnmount() {
            api.cancel(this.rideUrl);
            api.cancel('passengers-count');
        }

        get rideId() {
            const { match, rideId } = this.props;
            const rideFromProps =
                rideId || (match && match.params && match.params.id);

            return rideFromProps;
        }

        get rideUrl() {
            if (this.isLegacyId) {
                // for legacy ride id use dedicated entry point
                return this.rideUrlV1;
            }
            return this.rideUrlV2;
        }

        get rideUrlV1() {
            return `/ride/${this.rideId}`;
        }

        get rideUrlV2() {
            return `/ride/v2/${this.rideId}`;
        }

        fetchPaxCount() {
            const { meta } = this.state;
            const { ride } = meta;
            const { setRideMeta } = this.props;
            if (ride.uuid) {
                api.get(`/ride/v2/${ride.uuid}/passengers-count`, null, {
                    cancelTokenId: 'passengers-count',
                })
                    .then((count) => {
                        this.setState({ meta: { ...meta, paxCount: count } });
                        setRideMeta({ ...meta, paxCount: count });
                    })
                    .catch((e) => {
                        if (!api.isCancel(e)) {
                            const { notify, translate } = this.props;
                            notify({
                                type: 'danger',
                                message: translate(
                                    'notify.ride_info.pax-count-error'
                                ),
                            });
                        }
                    });
            }
        }

        get isLegacyId() {
            return Number.isInteger(Number(this.rideId));
        }

        fetchRideInfo() {
            const { notify, translate, setRideMeta, history, location } =
                this.props;
            const dispatcher = this.context;
            this.setState({ isLoading: true });

            api.get(this.rideUrl)
                .then(({ result }) => {
                    if (this.isLegacyId) {
                        // redirect to evn with uuid
                        legacyIdRedirectAction(this.rideId);
                        history.replace(
                            location.pathname.replace(
                                this.rideId,
                                result.ride.uuid
                            )
                        );
                        return;
                    }
                    this.setState(() => {
                        setRideMeta(result);
                        dispatcher.trigger({
                            type: 'ride-meta.uuid',
                            payload: result?.ride?.uuid,
                        });
                        return {
                            meta: { ...result },
                            isLoading: false,
                            notFound: false,
                        };
                    });
                    // fetch ride info with legacy ID from V1 entry point
                    api.get(this.rideUrlV1)
                        .then(({ result }) => {
                            this.setState((state) => {
                                const { meta } = state;
                                // patch ride meta data with legacy ID
                                const newMeta = {
                                    ...meta,
                                    ride: {
                                        ...meta.ride,
                                        id: result?.ride?.id,
                                        line_variation:
                                            result?.ride?.line_variation,
                                    },
                                };
                                setRideMeta(newMeta);
                                return {
                                    meta: newMeta,
                                };
                            });
                        })
                        .catch((error) => {
                            if (!api.isCancel(error)) {
                                notify({
                                    type: 'danger',
                                    message: 'Ride legacy ID does not laded',
                                });
                            }
                        });
                })
                .catch((error) => {
                    if (!api.isCancel(error)) {
                        if (error.response && error.response.status === 404) {
                            this.setState({ isLoading: false, notFound: true });
                            notify({
                                type: 'warning',
                                message: translate(
                                    'notify.ride_info.not_found'
                                ),
                            });
                        } else {
                            notify({
                                type: 'danger',
                                message: translate(
                                    'notify.ride_info.loading_error'
                                ),
                            });
                        }
                    }
                });
        }

        render() {
            const { isLoading, notFound, meta } = this.state;
            const { translate } = this.props;
            if (notFound) {
                return (
                    <div className="ride-view__context-block--narrow">
                        <Box>
                            <strong>{this.rideId}</strong>{' '}
                            {translate('ride_info.not_found.text')}{' '}
                            <Link to="/">
                                {translate('ride_info.not_found.text_link')}
                            </Link>
                        </Box>
                    </div>
                );
            }

            if (isLoading) {
                return (
                    <Box aria-live="polite" aria-busy="true">
                        <Grid>
                            <GridCol size={5}>
                                <Skeleton width="md" height="lg" />
                                <Skeleton Elem="div" width="lg" height="sm" />
                                <Skeleton Elem="div" height="sm" />
                            </GridCol>
                        </Grid>
                    </Box>
                );
            }
            return <Component {...this.props} meta={meta} />;
        }
    };
}
