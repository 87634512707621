import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, IconBoosterSolid } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../System/Translations';
import { SeatsAndCoaches, SeatsList } from './SeatsAndCoaches';

const SeatAssignment = ({ isBus, passengers, coachesMap, onSelect }) => {
    const t = useContext(TranslateContext);
    const [selectedOptions, setSelectedOptions] = useState({});

    useEffect(() => {
        onSelect(selectedOptions);
    }, [onSelect, selectedOptions]);

    function selectSeat(ticketId, option) {
        setSelectedOptions((prev) => {
            const newSelection = { ...prev };
            if (option === null) {
                delete newSelection[ticketId];
                return newSelection;
            }
            newSelection[ticketId] = option;
            return newSelection;
        });
    }

    function selectedSeatsList(options) {
        return options.map((option) => option.id);
    }

    return (
        <>
            <div className="seat-assignment-dialog__seats-row">
                <div className="seat-assignment-dialog__seats-label"></div>
                <div className="seat-assignment-dialog__seats-list">
                    {!isBus ? (
                        <div className="seat-assignment-dialog__seats-select">
                            {t('reservations.table.coach')}
                        </div>
                    ) : null}
                    <div className="seat-assignment-dialog__seats-select">
                        {t('reservations.table.seats')}
                    </div>
                </div>
            </div>
            {passengers.map((pax, idx) => {
                return (
                    <React.Fragment key={pax.ticket_id + idx}>
                        <div
                            className="seat-assignment-dialog__seats-row"
                            key={pax.ticket_id}
                        >
                            <div className="seat-assignment-dialog__seats-label">
                                <span>
                                    {pax.child_on_lap && (
                                        <Icon
                                            extraClasses="child-icon"
                                            InlineIcon={IconBoosterSolid}
                                        />
                                    )}
                                    <span>{pax.name}</span>
                                </span>
                                {selectedOptions[pax.ticket_id] ? (
                                    <span>
                                        {!isBus
                                            ? `${
                                                  selectedOptions[pax.ticket_id]
                                                      .vehicleLabel
                                              } | `
                                            : null}
                                        {selectedOptions[pax.ticket_id].label}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="seat-assignment-dialog__seats-list">
                                {!isBus ? (
                                    <SeatsAndCoaches
                                        coachesMap={coachesMap}
                                        pax={pax}
                                        onSelect={(option) => {
                                            selectSeat(pax.ticket_id, option);
                                        }}
                                        disabled={pax.child_on_lap}
                                        onReset={() => {}}
                                        selected={
                                            selectedOptions[pax.ticket_id]
                                        }
                                        selectedList={selectedSeatsList(
                                            Object.values(selectedOptions)
                                        )}
                                    />
                                ) : (
                                    <SeatsList
                                        seats={coachesMap.get('0')}
                                        pax={pax}
                                        onSelect={(option) => {
                                            selectSeat(pax.ticket_id, option);
                                        }}
                                        disabled={pax.child_on_lap}
                                        onReset={() => {}}
                                        selected={
                                            selectedOptions[pax.ticket_id]
                                        }
                                        selectedList={selectedSeatsList(
                                            Object.values(selectedOptions)
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
        </>
    );
};

SeatsAndCoaches.propTypes = {
    passengers: PropTypes.array.isRequired,
    availableCoaches: PropTypes.array.isRequired,
    availableSeats: PropTypes.array.isRequired,
    coachesMap: PropTypes.object.isRequired,
};
SeatsAndCoaches.defaultProps = {
    passengers: [],
    availableCoaches: [],
    availableSeats: [],
    coachesMap: {},
};

export default SeatAssignment;
