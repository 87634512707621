import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useLocation } from 'react-router';
import { Grid, GridCol } from '@flixbus/honeycomb-react';
import SearchForm from './SearchForm';
import { NotifyContext } from '../NotificationSystem/context';
import { TranslateContext } from '../System/Translations';
import {
    SearchResults,
    LoadMore,
    SelectionAndExpand,
    SelectAll,
} from './SearchResults';
import { ScrollToTop } from '../ScrollToTop';
import RideActions from './SearchResults/RideActions/RideActions';
import * as FILTERS from './SearchForm/formMap';
import { getBrowserTimezone } from '../../util/date';
import useRideSearch from './hooks/useRideSearch';
import RideSearchBanner from './RideSearchBanner';
import './style.scss';

export const COOKIE_SEARCH_PARAMS_KEY = 'rv-search-params';
export const COOKIE_SEARCH_RESULT_TOTAL = 'rv-search-total';

/**
 * help to support two different data scheme
 * to provide results
 * @func getResults
 * @param {array|object} data
 * @returns {object}
 */
function getResults(data) {
    if (data === null) {
        return {};
    }

    if (Array.isArray(data)) {
        return {
            totalResults: data.length,
        };
    }

    return {
        totalResults: data?.metadata?.total,
        limit: data?.metadata?.limit,
        offset: data?.metadata?.offset,
    };
}

function normalizeFormData(formData) {
    return Object.entries(formData).reduce((acc, entry) => {
        let result = { ...acc };
        const [key, value] = entry;
        result[key] = Array.isArray(value)
            ? value.join(key === FILTERS.CONCESSION_OWNERS ? ',,' : ',')
            : value;
        return result;
    }, {});
}

const STORAGE_RESULTS_EXPANDED_KEY = 'rv-storage-results-expanded';

export default function RideSearch() {
    const requestParamsCounter = useRef(0);
    const [requestParams, setRequestParams] = useState({});
    const {
        data,
        error,
        isFetching: pending,
        refetch,
    } = useRideSearch(requestParams);

    const notify = useContext(NotifyContext);

    const { totalResults, limit, offset } = getResults(data);

    const translate = useContext(TranslateContext);

    // TODO: rework this with reducer
    const [shown, setShown] = useState(0);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState([]);
    const [expanded, setExpanded] = useState(() => {
        const localStorageValue = window.localStorage.getItem(
            STORAGE_RESULTS_EXPANDED_KEY
        );
        const storageValue = localStorageValue
            ? JSON.parse(localStorageValue)
            : false;

        return storageValue || false;
    });
    const [searched, setSearched] = useState(false);
    const [loadedMore, setLoadedMore] = useState(false);
    const [disableLoadMore, setDisableLoadMore] = useState(false);

    // avoid search request when requestParams has initial value
    useEffect(() => {
        if (requestParamsCounter.current >= 1) {
            refetch();
        }
        requestParamsCounter.current++;
    }, [requestParams, refetch]);

    useEffect(() => {
        if (error) {
            notify({ type: 'danger', message: error.toString() });
        }
    }, [error, notify]);

    function saveShownAmountAndParams(params, shownAmount) {
        sessionStorage.setItem(
            COOKIE_SEARCH_PARAMS_KEY,
            JSON.stringify(params)
        );
        sessionStorage.setItem(
            COOKIE_SEARCH_RESULT_TOTAL,
            JSON.stringify(shownAmount)
        );
    }

    function onSubmit(formData) {
        formData.timezone =
            formData.timezone !== undefined
                ? formData.timezone
                : getBrowserTimezone();
        const normalizedFormData = normalizeFormData(formData);
        setResults([]);
        setSelected([]);
        setShown(0);
        setLoadedMore(false);
        setRequestParams({ ...normalizedFormData });
        setSearched(true);
    }

    function loadMore() {
        setLoadedMore(true);

        if (!disableLoadMore) {
            const loadOffset = limit + offset;
            let params = {
                ...requestParams,
                offset: loadOffset,
            };
            setRequestParams(params);
        }
    }

    const showResults = () => {
        if (data && data.results && data.results.length) {
            const storeResults = loadedMore
                ? [...results, ...data.results]
                : data.results;
            const shownAmount = loadedMore
                ? storeResults.length
                : data.results && data.results.length;

            setResults(storeResults);
            setShown(shownAmount);

            if (totalResults - shown > limit) {
                setDisableLoadMore(false);
            } else {
                setDisableLoadMore(true);
            }

            const requestParamsToSave = { ...requestParams };
            delete requestParamsToSave.offset;
            saveShownAmountAndParams(requestParamsToSave, shownAmount);
        }
    };

    useEffect(showResults, [data, totalResults]);

    const selectRides = (selectAll) => {
        setSelected(selectAll ? [...results] : []);
    };

    const selectRide = (rideId) => () => {
        const rideToSelect = selected.find((ride) => ride.uuid === rideId);

        if (rideToSelect) {
            setSelected(
                [...selected].filter((ride) => ride.uuid !== rideToSelect.uuid)
            );
        } else {
            const rideToSelect = results.find((ride) => ride.uuid === rideId);
            const newSelection = [...selected];
            newSelection.push(rideToSelect);

            setSelected(newSelection);
        }
    };

    const selectFoundRides = (rides) => {
        setSelected([...rides]);
    };

    const expandRides = () => {
        const newValue = !expanded;
        setExpanded(newValue);

        try {
            window.localStorage.setItem(STORAGE_RESULTS_EXPANDED_KEY, newValue);
        } catch (e) {
            console.error(e);
        }
    };

    const selectRidesCb = useCallback(selectRides, [results]);
    const expandRidesCb = useCallback(expandRides, [results, expanded]);
    const selectRideCb = useCallback(selectRide, [results, selected]);

    // prevent blinking of expanded items with filters values changed
    const MemoSearchResults = useMemo(
        () => (
            <SearchResults
                results={results}
                loading={pending}
                selectRide={selectRideCb}
                selectedRides={selected}
                expanded={expanded}
            />
        ),
        [results, pending, selectRideCb, selected, expanded]
    );

    const { search } = useLocation();

    return (
        <React.Fragment>
            <div className="ride-search">
                <RideSearchBanner />
                <div className="ride-view__context-block--narrow">
                    <SearchForm onSubmit={onSubmit} loading={pending} />
                </div>
            </div>
            <div className="ride-search-results-info">
                <div className="ride-view__context-block--narrow">
                    {totalResults && !pending ? (
                        <Grid align="center">
                            <GridCol size={8}>
                                <div className="results-header">
                                    <SelectionAndExpand
                                        selectRides={selectRidesCb}
                                        expandRides={expandRidesCb}
                                        expanded={expanded}
                                    />

                                    <span className="frt">
                                        {translate('ride-search.total-results')}
                                        &nbsp;
                                        {totalResults}
                                    </span>
                                    {shown ? (
                                        <span className="frt">
                                            &nbsp;&nbsp;
                                            {translate(
                                                'ride-search.show-limit'
                                            )}
                                            &nbsp;
                                            {shown}
                                        </span>
                                    ) : null}
                                    {selected.length ? (
                                        <span className="frt">
                                            &nbsp;&nbsp;
                                            {translate(
                                                'ride-search.selected-results'
                                            )}
                                            &nbsp;
                                            {selected.length}
                                        </span>
                                    ) : null}
                                    {selected.length > 0 ? (
                                        <SelectAll
                                            selectRides={selectFoundRides}
                                            formData={requestParams}
                                            totalResults={totalResults}
                                        />
                                    ) : null}
                                </div>
                            </GridCol>
                            <GridCol size={4}>
                                <RideActions
                                    selected={selected}
                                    searchParams={search}
                                />
                            </GridCol>
                        </Grid>
                    ) : null}
                </div>
            </div>
            <div className="rides-list">
                <div className="ride-view__context-block--narrow">
                    {results.length > 0 && (
                        <>
                            {MemoSearchResults}
                            <LoadMore
                                loadMore={loadMore}
                                loading={pending}
                                disabled={disableLoadMore}
                            />
                        </>
                    )}
                    {results.length <= 0 && searched && !pending ? (
                        <p style={{ textAlign: 'center' }}>
                            {translate('notifier.templates.no_results_found')}
                        </p>
                    ) : null}
                </div>
            </div>

            <ScrollToTop />
        </React.Fragment>
    );
}
