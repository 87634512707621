import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/Client';
import { DispatcherContext } from '../../System/Dispatcher';

export default function withCancelApi(Component) {
    return class WithCancelApi extends React.Component {
        static contextType = DispatcherContext;

        static propTypes = {
            rideMeta: PropTypes.shape({
                ride: PropTypes.shape({
                    is_cancelled: PropTypes.bool,
                }),
            }).isRequired,
            notify: PropTypes.func,
            setRideMeta: PropTypes.func,
        };

        static defaultProps = {
            notify: () => {},
            setRideMeta: () => {},
        };

        state = {
            isLoading: false,
        };

        componentDidMount() {
            this.dispatcher = this.context;
        }

        triggerTagEvent = () => {
            this.dispatcher.trigger('set-canceled-tag');
        };

        cancelRide = (notifyPassengers, reasonId, reasonText) => {
            const { rideMeta } = this.props;
            return api.post(`/ride/v2/${rideMeta.ride.uuid}/cancel`, {
                notifyPassengers,
                reasonId,
                reasonText,
            });
        };

        unCancelRide = () => {
            const { rideMeta } = this.props;
            return api.post(`/ride/v2/${rideMeta.ride.uuid}/uncancel`, {});
        };

        processCancellation = (notifyPassengers, reasonId, reasonText) => {
            const { rideMeta, notify, setRideMeta } = this.props;
            let promise;

            this.setState({ isLoading: true }, () => {
                if (!rideMeta.ride.is_cancelled) {
                    promise = this.cancelRide(
                        notifyPassengers,
                        reasonId,
                        reasonText
                    );
                } else {
                    promise = this.unCancelRide();
                }
                promise
                    .then((response) => {
                        this.setState({ isLoading: false });
                        notify({
                            type: 'success',
                            message: `Ride was ${
                                response.ride.is_cancelled
                                    ? 'Cancelled'
                                    : 'Uncancelled'
                            }`,
                        });
                        rideMeta.ride = response.ride;
                        setRideMeta(rideMeta);
                        this.triggerTagEvent();
                    })
                    .catch((error) => {
                        if (!api.isCancel(error)) {
                            console.error(error);
                            notify({
                                type: 'danger',
                                message:
                                    'Ride Cancellation: Unexpected error occurred.',
                            });
                            this.setState({ isLoading: false });
                        }
                    });
            });
        };

        render() {
            const { isLoading } = this.state;
            return (
                <Component
                    {...this.props}
                    processCancellation={this.processCancellation}
                    isLoading={isLoading}
                />
            );
        }
    };
}
